import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Paper } from "@material-ui/core";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/publicReducer/actions";
import { FaHeart } from "react-icons/fa";
import packageJson from "../../../package.json";
import "./SI180Dashboard.css";
import { loginPin } from "../../services/api/si180Api";
import Swal from "sweetalert2";
import LockIcon from "@mui/icons-material/Lock";

const primColor = process.env.REACT_APP_SI180_PRIMCOLOR;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const btnColor = process.env.REACT_APP_SI180_BTNCOLOR;
const secColor = process.env.REACT_APP_SI180_SECCOLOR;
const greyColor = process.env.REACT_APP_SI180_BGGREY;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

// Number Pad Component
const NumberPad = ({ handleNumberClick }) => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, "Cancel", 0, "Delete"]; // Empty string for blank spaces

  return (
    <div style={{ margin: ".5rem 0rem", textAlign: "center" }}>
      {Array.from(Array(4)).map((_, rowIndex) => (
        <div
          key={rowIndex}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {numbers
            .slice(rowIndex * 3, rowIndex * 3 + 3)
            .map((number, index) => (
              <Button
                key={index}
                variant="contained"
                style={{
                  margin: ".3rem",
                  width: "5rem",
                  height: "3.25rem",
                  fontSize: "1.25rem",
                  backgroundColor: secColor,
                  color: textColor,
                  textTransform: "capitalize",
                }}
                onClick={() => handleNumberClick(number)}
              >
                {number}
              </Button>
            ))}
        </div>
      ))}
    </div>
  );
};

export const PINSignin = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loginError, setLoginError] = useState("");
  const [pin, setPin] = useState(Array(6).fill(""));
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const history = useHistory();

  // Update current time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // Update current date once per day
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setCurrentDate(new Date());
      },
      1000 * 60 * 60 * 24
    ); // 24 hours

    return () => clearInterval(intervalId);
  }, []);

  const handlePinChange = (e, index) => {
    const { value } = e.target;

    // Update the pin array with the entered value
    const newPin = [...pin];
    newPin[index] = value;

    // If the input is not empty and is not the last box, move focus to the next input
    if (value && index < 3) {
      const nextInput = e.target.parentNode.nextSibling.querySelector("input");
      if (nextInput) {
        nextInput.focus();
      }
    }

    // Update the pin state with the new value
    setPin(newPin);
  };

  const handleNumberClick = (number) => {
    if (number === "Cancel") {
      // Reset the entire PIN
      setPin(Array(6).fill(""));
    } else if (number === "Delete") {
      const newPin = [...pin];
      // Find the last non-empty index from the end of the array
      const lastEnteredIndex = newPin
        .slice()
        .reverse()
        .findIndex((digit) => digit !== "");
      const indexToDelete =
        lastEnteredIndex >= 0
          ? newPin.length - 1 - lastEnteredIndex
          : lastEnteredIndex;

      // If a non-empty index is found, clear it
      if (indexToDelete !== -1) {
        newPin[indexToDelete] = "";
        setPin(newPin);
      }
    } else {
      // Handling number input
      const nextEmptyIndex = pin.findIndex((entry) => entry === "");
      if (nextEmptyIndex !== -1) {
        const newPin = [...pin];
        newPin[nextEmptyIndex] = String(number);
        setPin(newPin);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate PIN if needed
    dispatch(setLoading(true)); // Dispatch action for signing in
    console.log(pin.join(""));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    try {
      const data = await loginPin(pin.join(""));
      console.log("Login successful:", data);
      if (data && data.length > 0) {
        sessionStorage.setItem("currentUser", JSON.stringify(data));
        history.push("/dashboard");
      } else {
        setPin(Array(6).fill(""));
        Swal.fire({ icon: "error", title: "Oops...", text: "Invalid PIN" });
      }
      dispatch(setLoading(false));
    } catch (error) {
      console.error("Login failed:", error);
      setLoginError(
        "Failed to login. Please check your credentials and try again."
      );
      // Update the UI to reflect the error
    }
  };

  if (user) {
    // Handle redirection based on user type
    if (user?.userType === "CUSTOMER") {
      if (state?.restaurantId) {
        return <Redirect to={`/${state?.restaurantId}/checkout`} />;
      } else {
        return <Redirect to="/home" />;
      }
    }
    if (user?.userType === "ADMIN") {
      return <Redirect to="/admin" />;
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          left: "1rem",
          color: secColor,
          opacity: "50%",
        }}
      >
        <Typography variant={"subtitle2"}>
          {currentTime.toLocaleTimeString()}
        </Typography>
      </div>
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "1rem",
          color: secColor,
          opacity: "50%",
        }}
      >
        <Typography variant={"subtitle2"}>
          {currentDate.toLocaleDateString()}
        </Typography>
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh", backgroundColor: primColor }}
      >
        <Grid item xs={12} md={6} lg={4} style={{ zIndex: "9" }}>
          <Paper
            square={true}
            style={{
              padding: ".5rem",
              minHeight: "50vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "10px",
              backgroundColor: greyColor,
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                padding: ".5rem 0",
                // borderBottom: "1px solid #eee",
                // position: "fixed",
                // top: 0,
                // left: 0,
                zIndex: 1000,
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://servingintel.com/"
              >
                <img
                  alt="SI180 typemark"
                  src={siteMedSrc + "SI-180" + ".svg"}
                  style={{
                    maxWidth: "100px",
                    marginTop: ".5rem",
                    opacity: "81%",
                  }}
                />
              </a>
            </div>
            <Typography
              style={{
                textAlign: "center",
                margin: ".5rem 0rem",
              }}
              variant={"h6"}
            >
              Enter Your PIN
            </Typography>
            <form onSubmit={handleLogin}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {[...Array(4)].map((_, index) => (
                  <input
                    key={index}
                    name={`pin${index}`}
                    type="password"
                    value={pin[index]}
                    onChange={(e) => handlePinChange(e, index)}
                    style={{
                      width: "2rem",
                      height: "2rem",
                      margin: "0.5rem",
                      textAlign: "center",
                      fontSize: "1.5rem",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                    minLength={1}
                    maxLength={1}
                    required
                  />
                ))}
              </div>
              <NumberPad handleNumberClick={handleNumberClick} />{" "}
              {/* Number Pad Component */}
              <div style={{ marginTop: "0.5rem" }}>
                <Button
                  className="si180btn"
                  variant="contained"
                  style={{
                    width: "100%",
                    backgroundColor: btnColor,
                    color: textColor,
                  }}
                  type="submit"
                >
                  Enter
                </Button>
              </div>
            </form>
            <div
              style={{
                marginTop: ".5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: secColor,
                textAlign: "center",
              }}
            >
              <Typography
                style={{
                  lineHeight: 1,
                  fontSize: "small",
                }}
              >
                <LockIcon
                  style={{
                    marginBottom: "2px",
                    fontSize: "small",
                  }}
                />
                SI180 secures your transaction data with advanced encryption
                methods, ensuring the confidentiality and integrity of your
                information.
              </Typography>
            </div>
          </Paper>
        </Grid>
        <footer
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            color: secColor,
            padding: ".5rem 0",
            fontFamily: '"Arial", sans-serif',
            fontSize: "14px",
            opacity: "50%",
            zIndex: 1,
          }}
        >
          <div className="widget heart">
            <p style={{ marginBottom: ".25rem" }}>
              © {new Date().getFullYear()} {restaurantName}, made with{" "}
              <FaHeart style={{ color: "red" }} /> for a better App. Version{" "}
              {packageJson.version}
            </p>
            <p style={{ marginBottom: ".5rem" }}>
              Have questions? Visit{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://servingintel.com/"
                style={{ color: "lightblue" }}
              >
                ServingIntel.com
              </a>
            </p>
          </div>
        </footer>
      </Grid>
    </React.Fragment>
  );
};
