import React, { use, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Grid,
  Typography,
  CardMedia,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Modal,
  IconButton,
  List,
  Badge,
  TextField,
} from "@material-ui/core";
import ShoppingCartIcon from "@mui/icons-material/AssignmentOutlined";
import { green } from "@mui/material/colors";
import LeftNav from "./LeftNav";
import "./SI180Dashboard.css";
import Pagination from "@material-ui/lab/Pagination";
import {
  fetchCategoryItems,
  fetchTableLocation,
  fetchAllMenuItems,
  setOrder,
  sendOrder,
  sendOrderForLater,
} from "services/api/si180Api";
import Table from "@mui/material/Table";
import TableFooter from "@mui/material/TableFooter";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { groupByMenu } from "services/dataOperations";
import MenuItemsModifiers from "./MenuItemsModifiers";
import Autocomplete from "./AutoComplete";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setOrderProcessingDate } from "../../redux/publicReducer/actions";
import moment from "moment";
import DietaryAllergyDialog from "./DietaryAllergyDialog";
import DietaryAllergyPane from "./DietaryAllergyPane";
import { Form, Input } from "components";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import { BorderColor } from "@mui/icons-material";
import NoFoodIcon from "@mui/icons-material/NoFood";
import Tooltip from "@mui/material/Tooltip";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minWidth: 200,
    backgroundColor: theme.palette.grey[300], // Assuming you have greyColor defined in your theme
  },
  label: {
    fontSize: "1rem",
  },
  menuItem: {
    fontSize: "1rem",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto", // Adjust width based on the content or set max-width
  maxHeight: "100vh", // Maximum height to allow scrolling
  overflowY: "auto", // Enable vertical scrolling
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const serverId = process.env.REACT_APP_SERVER_ID;
const primColor = process.env.REACT_APP_SI180_PRIMCOLOR;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const btnColor = process.env.REACT_APP_SI180_BTNCOLOR;
const greyColor = process.env.REACT_APP_SI180_BGGREY;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const itemImgSrc = process.env.REACT_APP_IMAGE_SRC;
const secColor = process.env.REACT_APP_SI180_SECCOLOR;
const itemsPerPage = 8; // Number of items you want per page

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

const rows = [
  createRow("Apple Juice", 5, 3.0),
  createRow("Almond Salmon", 2, 15.0),
  createRow("Beef Goulash", 3, 7.49),
];

// Accessing the description of each item
const appleJuiceDesc = rows[0].desc; // "Apple Juice"
const almondSalmonDesc = rows[1].desc; // "Almond Salmon"
const beefGoulashDesc = rows[2].desc; // "Beef Goulash"

const invoiceSubtotal = rows.reduce((sum, row) => sum + row.price, 0);

const ResidentMenuItems = (props, { onResidentLookup }) => {
  const [checkNumber] = useState("12345");
  const selectedCustomer = JSON.parse(
    sessionStorage.getItem("selectedCustomer")
  );
  const [currentUser] = useState({
    firstName: selectedCustomer?.FirstName || "N/A",
    lastName: selectedCustomer?.LastName || "N/A",
    roomNumber: selectedCustomer?.Address1,
    residentImg: selectedCustomer?.SIC_Photo_Filename,
  });
  const [selectedAddress] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: "",
    address2: "",
    city: "",
    zipCode: "",
  });
  const [searchQuery, setSearchQuery] = useState(
    props?.location?.state?.searchedItem || ""
  );
  const [page, setPage] = useState(1);

  const [allMenuItems, setAllMenuItems] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    sessionStorage.getItem("selectedCategory")
  );

  const [openModal, setOpenModal] = useState(false);

  const [selectedItem, setSelectedItem] = useState();
  const [itemCount, setItemCount] = useState(1);
  const [modifiers, setModifiers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = React.useState(false);

  const [orderOption, setOrderOption] = useState("orderToday");

  const [open, setOpen] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [quantity, setQuantity] = useState();
  const [tableLocation, setTableLocation] = useState();
  const [tableNumber, setTableNumber] = useState("");
  const [serviceCharge, setServiceCharge] = useState(5.0);

  const cartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];
  const groupCartItems = groupByMenu(cartItems);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const orderOptions = ["Now", "Today", "Tomorrow"];
  const selectedOrderIndex = parseInt(
    sessionStorage.getItem("selectedOrderOption"),
    10
  );
  const selectedOrderText = orderOptions[selectedOrderIndex] || "Tomorrow"; // Default to "Tomorrow" if index is out of bounds
  const [openDiet, setOpenDiet] = useState(false);
  // console.log("selectedOrderIndex", selectedOrderIndex);

  const [messageToKitchen, setMessageToKitchen] = useState(
    sessionStorage.getItem("instuctions")
  );

  const initialValues = {
    message: "",
  };

  const validationSchema = Yup.object({
    message: Yup.string(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { watch, setValue } = methods;
  const watchMessage = watch("message");
  // console.log(watchMessage, "MESSAGE");
  const handleSubmit = (d) => {
    // console.log(d);
  };

  const { orderProcessingDate } = useSelector((state) => state.public);
  const [cookieOrderId, setCookieOrderId, removeCookieOrderId] = useCookies([
    "",
  ]);

  useEffect(() => {
    dispatch(setOrderProcessingDate(moment()));
  }, []);

  useEffect(() => {
    if (selectedOrderIndex !== 0) {
      setTableNumber("None");
    } else {
      setTableNumber(
        JSON.parse(sessionStorage.getItem("selectedCustomer")).Address1
      );
    }
  }, [selectedOrderIndex]);

  // Fetch all menu items once when the component mounts
  useEffect(() => {
    const fetchMenuAllCategories = async () => {
      try {
        const data = await fetchAllMenuItems();
        setAllMenuItems(data);
      } catch (error) {
        console.error("fetchAllItems failed:", error);
      }
    };

    fetchMenuAllCategories();
  }, []);

  // Fetch all category items for the selected category or search query
  useEffect(() => {
    const fetchSelectedCategoryItems = async () => {
      try {
        const data = await fetchCategoryItems();
        setCategoryItems(data);
        setPage(1); // Reset to page 1 when category items are fetched/updated
      } catch (error) {
        console.error("fetchCategoryItems failed:", error);
      }
    };

    if (selectedCategory) {
      fetchSelectedCategoryItems();
    }
  }, [selectedCategory, props?.location?.state?.searchedItem]);

  useEffect(async () => {
    try {
      const data = await fetchTableLocation();

      setTableLocation(
        data.sort((a, b) => {
          return a.TableName.localeCompare(b.TableName, undefined, {
            numeric: true,
          });
        })
      );
    } catch (error) {
      console.error("fetchTableLocation failed:", error);
    }
  }, []);

  useEffect(() => {
    setSearchQuery(props?.location?.state?.searchedItem || "");
    const handleStorageChange = () => {
      setSelectedCategory(sessionStorage.getItem("selectedCategory"));
    };

    window.addEventListener("storageChange", handleStorageChange);

    return () => {
      window.removeEventListener("storageChange", handleStorageChange);
    };
  }, [props?.location?.state?.searchedItem]);

  useEffect(() => {
    console.log(
      `user selected a Table Number? ${tableNumber.includes("Table")} (${tableNumber})`
    );

    if (tableNumber.includes("Table")) {
      setServiceCharge(0);
    } else {
      setServiceCharge(5.0);
    }
  }, [tableNumber]);

  const handleTableChange = (event) => {
    setTableNumber(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(1); // Reset to first page on search
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCheckout = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const filteredItems = (
    searchQuery === "" ? categoryItems : allMenuItems
  ).filter((item) =>
    item.ItemName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const numRows = Math.ceil(filteredItems.length / 3);

  // const paginatedItems = filteredItems.slice(
  //   (page - 1) * itemsPerPage,
  //   page * itemsPerPage
  // );

  const handleOrderOptionChange = (event) => {
    setOrderOption(event.target.value);
  };

  const timer = React.useRef();

  const buttonSx = {
    ...(success
      ? {
          bgcolor: "#265E08",
          "&:hover": {
            bgcolor: green[700],
          },
        }
      : {
          backgroundColor: "#265E08",
        }),
    color: textColor,
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleOpenEdit = (item) => {
    const cartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];
    const cartItem = cartItems.find((i) => i.ItemID === item.ItemID);
    setSelectedItem(cartItem);
    setItemCount(cartItem?.quantity);
    setOpenEdit(true);
  };
  const handleCloseEdit = () => setOpenEdit(false);
  const handleDeleteEdit = () => {
    // Handle delete logic
    console.log("Item deleted");
    handleCloseEdit();
  };
  const handleSaveEdit = () => {
    // Handle update logic
    console.log("Changes saved");
    handleCloseEdit();
  };

  const handleClickOpenSubmit = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    console.log("Order confirmed");
    // Process the order submission logic here
    setOpen(false);
  };

  const handleClickOpen = (item) => {
    setSelectedItem(item);
    setItemCount(1);
    setOpen(true);
  };

  const handleClickOpenDiet = () => {
    setOpenDiet(true);
  };

  const handleCloseDiet = () => {
    setOpenDiet(false);
  };

  // Set kitchen message to sessionStorage
  const setKitchenMsg = (e) => {
    sessionStorage.setItem("instuctions", e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddToCheck = () => {
    const cartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];
    // Find the item in the cart
    const existingItemIndex = cartItems.findIndex(
      (item) => item.ItemID === selectedItem.ItemID
    );

    // If the item is already in the cart, update its quantity
    if (existingItemIndex !== -1) {
      const updatedCartItems = cartItems.map((item, index) => {
        if (index === existingItemIndex) {
          return {
            ...item,
            quantity: openEdit ? itemCount : item.quantity + itemCount,
            modifiers: openEdit ? modifiers : item.modifiers, // Update modifiers if in edit mode
          };
        }
        return item;
      });

      // Update cart items in sessionStorage
      sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    } else {
      // If the item is not in the cart, add it
      const updatedCartItems = [
        ...cartItems,
        {
          ...selectedItem,
          quantity: itemCount,
          modifiers: modifiers,
          menu: sessionStorage.getItem("selectedMenu"),
        },
      ];

      // Update cart items in sessionStorage
      sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }

    console.log("cartItems", cartItems);
    // Reset the modifiers state after adding to cart
    setModifiers([]);
    handleClose();
  };

  const formatCurrency = (price) => {
    return parseFloat(price).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  const getSubtotalPrice = () => {
    const subtotalPrice = cartItems.reduce((subtotal, item) => {
      return parseFloat(
        subtotal + parseFloat(item.DefaultPrice) * item.quantity
      );
    }, 0); // Start the subtotalPrice at 0

    return subtotalPrice;
  };

  const getTotalTaxAmount = () => {
    const totalTaxAmount = cartItems.reduce((total, item) => {
      return parseFloat(total + parseFloat(item.TaxTotal) * item.quantity);
    }, 0); // Start the taxTotal at 0

    return totalTaxAmount;
  };

  const getTotalDiscount = () => {
    const totalDiscount = cartItems.reduce((total, item) => {
      return parseFloat(total + parseFloat(item.DiscountTotal) * item.quantity);
    }, 0); // Start the totalDiscount at 0

    return totalDiscount;
  };

  const getTotalPrice = () => {
    // Calculate total price by summing up the price * quantity for each item
    const totalPrice = cartItems.reduce((total, item) => {
      return total + item.DefaultPrice * item.quantity;
    }, 0); // Start the total at 0

    return totalPrice / 100 + serviceCharge;
    // return (
    //   getSubtotalPrice() +
    //   getTotalTaxAmount() +
    //   serviceCharge -
    //   getTotalDiscount()
    // );
  };

  const deleteItemFromCart = () => {
    // Filter out the item with the specified ItemID
    const updatedCartItems = cartItems.filter(
      (item) => item.ItemID !== selectedItem.ItemID
    );

    // Update cart items in sessionStorage
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const submitOrder = async () => {
    try {
      setIsLoading(true);
      // Cache sessionStorage items
      const orderId = sessionStorage.getItem("order_id");
      const selectedCustomer = sessionStorage.getItem("selectedCustomer");
      const instructions = sessionStorage.getItem("instuctions");

      selectedAddress.first_name = JSON.parse(selectedCustomer).FirstName;
      selectedAddress.last_name = JSON.parse(selectedCustomer).LastName;
      selectedAddress.email = JSON.parse(selectedCustomer).Email;
      selectedAddress.address = JSON.parse(selectedCustomer).Address1;

      // Handle date formatting
      const d = new Date(orderProcessingDate);
      const formattedDate = {
        date: d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(),
        time: d.getHours() + ":" + d.getMinutes(),
      };

      const payload = {
        cartItems: cartItems,
        total: parseFloat(getTotalPrice()),
        subTotal: parseFloat(getSubtotalPrice()),
        tax: parseFloat(getTotalTaxAmount()),
        discount: parseFloat(getTotalDiscount()),
        serviceCharge: parseFloat(serviceCharge),
        order_id: orderId ? parseInt(orderId) : 0, // Correct check for order_id
        orderProcessingType: selectedOrderText,
        orderProcessingDate: formattedDate,
        selectedAddress: selectedAddress,
        serverName: "SI180",
        tableNumber: tableNumber,
        customer: selectedCustomer ? JSON.parse(selectedCustomer) : null, // Parse selected customer
        instructions: instructions || "", // Fallback to empty string if instructions are missing
      };

      // Set the Order
      const data = await setOrder(payload);
      sessionStorage.setItem("order_id", data.order_id);
      sessionStorage.setItem("user_id", data.user_id);
      setCookieOrderId("orderId", data.order_id, { path: "/" });

      // Send the Order after setting order_id in sessionStorage

      // Determine if we should call sendOrderForLater or sendOrder based on selectedOrderText
      let isOrderSent;
      if (selectedOrderText === "Today" || selectedOrderText === "Tomorrow") {
        isOrderSent = await sendOrderForLater();
      } else {
        isOrderSent = await sendOrder();
      }

      if (isOrderSent) {
        // Show success message
        Swal.fire({
          title: "Order Submitted",
          text: "Your order has been submitted successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Clear session data and redirect
        sessionStorage.removeItem("cartItems");
        sessionStorage.removeItem("order_id");
        history.push("/resident-menu");
      } else {
        // Handle order sending failure
        Swal.fire({
          title: "Error",
          text: "There was a problem sending your order.",
          icon: "error",
          confirmButtonText: "Retry",
        });
        console.log("Order failed to send!");
      }
    } catch (error) {
      // Log error and show user-friendly message
      console.error("Order submission error:", error);
      Swal.fire({
        title: "Error",
        text: "There was a problem submitting your order. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard">
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={isLoading}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <p style={{ marginTop: "10px", fontSize: "18px" }}>
            Sending Order...
          </p>
        </div>
      </Backdrop>
      <div>
        <LeftNav onResidentLookup={onResidentLookup} />
      </div>
      <div className="right-pane" style={{ backgroundColor: "white" }}>
        <IconButton
          onClick={handleCheckout}
          style={{
            position: "absolute",
            right: 20,
            top: 20,
            backgroundColor: "#265E08",
            padding: "1.125rem",
            color: textColor,
          }}
        >
          <Badge badgeContent={cartItems.length} color="primary">
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
        {/* Edit Item Modal   */}
        <Dialog
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="form-dialog-title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DialogTitle
            id="form-dialog-title"
            style={{
              color: "primary",
              fontWeight: 550,
            }}
          >
            Edit Item
          </DialogTitle>
          <IconButton
            onClick={handleCloseEdit}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              backgroundColor: "#d33",
              borderRadius: "50%",
              padding: "12px",
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minWidth: "250px",
                  height: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: "large",
                    textAlign: "left",
                    paddingRight: ".5rem",
                  }}
                >
                  {selectedItem?.ItemName}
                </Typography>
                <IconButton
                  style={{
                    color: textColor,
                    backgroundColor: btnColor,
                    padding: "1rem",
                  }}
                  onClick={() => {
                    deleteItemFromCart(selectedItem.ItemID);
                    handleCloseEdit();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "250px",
                height: "100%",
              }}
            >
              <Typography
                style={{
                  fontSize: "large",
                  textAlign: "left",
                  paddingRight: ".5rem",
                }}
              >
                Quantity:
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  marginTop: ".5rem",
                  height: "100%",
                }}
              >
                <IconButton
                  style={{
                    color: textColor,
                    backgroundColor: btnColor,
                    padding: "1rem",
                  }}
                  onClick={() =>
                    setItemCount((count) => Math.max(count - 1, 1))
                  }
                >
                  <RemoveIcon />
                </IconButton>
                <Box
                  style={{
                    padding: "0 .5rem",
                    fontSize: "large",
                    fontWeight: "550",
                  }}
                >
                  {itemCount}
                </Box>
                <IconButton
                  style={{
                    color: textColor,
                    backgroundColor: btnColor,
                    padding: "1rem",
                  }}
                  onClick={() => setItemCount((count) => count + 1)}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleAddToCheck();
                handleCloseEdit();
              }}
              className="si180btn"
              sx={buttonSx}
              style={{
                backgroundColor: "#265E08",
                color: textColor,
                minWidth: "150px",
                height: "72px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {/* End of Edit Item Modal */}

        {/* Order Information Modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ minHeight: "100%", overflowY: "auto" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={style}>
              <IconButton
                onClick={handleCloseModal}
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "white",
                  backgroundColor: "#d33",
                  borderRadius: "50%",
                  padding: "12px",
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  textAlign: "center",
                  fontWeight: 550,
                  marginBottom: ".5rem",
                }}
              >
                Order Information
              </Typography>
              <div style={{ textAlign: "center", color: primColor }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "75px",
                      height: "75px",
                      marginRight: ".5rem",
                    }}
                    className="dietary"
                  >
                    <Tooltip
                      title={
                        <span style={{ fontSize: "medium" }}>
                          Check Dietary and Allergen Info
                        </span>
                      }
                    >
                      <img
                        src={
                          currentUser.residentImg
                            ? `${itemImgSrc}${currentUser.residentImg}`
                            : `${itemImgSrc}respic.png`
                        }
                        alt={`${currentUser.firstName} ${currentUser.lastName}`}
                        style={{
                          width: "75px",
                          height: "75px",
                          borderRadius: "50%",
                          marginRight: ".5rem",
                          border: `3px solid ${btnColor}`,
                          cursor: "pointer",
                        }}
                        onClick={handleClickOpenDiet}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        <span style={{ fontSize: "medium" }}>
                          Check Dietary and Allergen Info
                        </span>
                      }
                    >
                      <IconButton
                        onClick={handleClickOpenDiet}
                        style={{
                          position: "absolute",
                          bottom: "-5px",
                          right: "-5px",
                          backgroundColor: "white",
                          border: `2px solid ${btnColor}`,
                          borderRadius: "50%",
                          padding: "3px",
                        }}
                      >
                        <NoFoodIcon style={{ color: btnColor }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <DietaryAllergyDialog
                    open={openDiet}
                    onClose={handleCloseDiet}
                  />
                  <div>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        lineHeight: "1.125",
                      }}
                    >
                      {currentUser.firstName} {currentUser.lastName}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        lineHeight: "1.125",
                      }}
                    >
                      Room #: {currentUser.roomNumber}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        lineHeight: "1.125",
                      }}
                    >
                      Check #: {checkNumber}
                    </Typography>
                  </div>
                </div>
              </div>
              <Box style={{ textAlign: "center" }}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: "210px" }}
                >
                  <InputLabel
                    id="table-select-label"
                    className={classes.label}
                    style={{
                      fontSize: "large",
                      textAlign: "left",
                      marginTop: ".75rem",
                      marginBottom: ".75rem",
                    }}
                  >
                    Select a Room or Table
                  </InputLabel>
                  <Select
                    labelId="table-select-label"
                    id="table-select"
                    value={tableNumber}
                    onChange={handleTableChange}
                    label="Select a Table"
                    style={{
                      fontSize: "larger",
                      textAlign: "left",
                      marginTop: ".25rem",
                    }}
                  >
                    {selectedOrderIndex !== 0 ? (
                      <MenuItem
                        value="None"
                        className={classes.menuItem}
                        style={{
                          fontSize: "larger",
                          textAlign: "left",
                          marginTop: ".75rem",
                        }}
                      >
                        <em>None</em>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={currentUser.roomNumber}
                        value={currentUser.roomNumber}
                        className={classes.menuItem}
                        style={{
                          fontSize: "larger",
                          textAlign: "left",
                          marginTop: ".5rem",
                        }}
                      >
                        Room {currentUser.roomNumber}
                      </MenuItem>
                    )}
                    {selectedOrderIndex === 0 &&
                      tableLocation?.map((table) => (
                        <MenuItem
                          key={table.SIC_ID}
                          value={table.TableName}
                          className={classes.menuItem}
                          style={{
                            fontSize: "larger",
                            textAlign: "left",
                            marginTop: ".5rem",
                          }}
                        >
                          {table.TableName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <TableContainer className="SpanningTable" component={Paper}>
                <Table aria-label="spanning table">
                  <div
                    style={{
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <TableHead>
                      {/* <TableRow>
                    <TableCell
                      align="center"
                      colSpan={3}
                      style={{ fontSize: "medium", color: textColor }}
                    >
                      Lunch Menu
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontSize: "medium", color: textColor }}
                    >
                      Price
                    </TableCell>
                  </TableRow> */}
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: "medium",
                            color: textColor,
                            padding: ".75rem",
                            lineHeight: 1.1,
                          }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontSize: "medium",
                            color: textColor,
                            padding: ".75rem",
                            lineHeight: 1.1,
                          }}
                        >
                          Unit Price
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: "medium",
                            color: textColor,
                            padding: ".75rem",
                            lineHeight: 1.1,
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            fontSize: "medium",
                            color: textColor,
                            padding: ".75rem",
                            lineHeight: 1.1,
                          }}
                        >
                          Total Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ fontSize: "medium" }}>
                      {Object.entries(groupCartItems).map(([menu, items]) => (
                        <>
                          <TableRow key={menu}>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                fontSize: "medium",
                                backgroundColor: secColor,
                              }}
                              colSpan={4}
                            >
                              {menu}
                            </TableCell>
                          </TableRow>
                          {items.map((item) => (
                            <React.Fragment key={item.ItemID}>
                              <TableRow>
                                <TableCell
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "left",
                                    fontSize: "medium",
                                    textAlign: "left",
                                    padding: ".5rem",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handleOpenEdit(item)}
                                    style={{
                                      paddingTop: "10px",
                                      zIndex: 1,
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item.ItemName}
                                  </span>
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{
                                    fontSize: "medium",
                                    padding: ".5rem",
                                  }}
                                >
                                  {/* {formatCurrency(
                                    parseFloat(item.DefaultPrice)
                                  )} */}
                                  {formatCurrency(item.DefaultPrice / 100)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{
                                    fontSize: "medium",
                                    padding: ".5rem",
                                  }}
                                >
                                  {item.quantity}
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{
                                    fontSize: "medium",
                                    padding: ".5rem",
                                  }}
                                >
                                  {/* {formatCurrency(
                                    parseFloat(item.DefaultPrice) *
                                      item.quantity
                                  )} */}
                                  {formatCurrency(
                                    (parseFloat(item.DefaultPrice) *
                                      item.quantity) /
                                      100
                                  )}
                                </TableCell>
                              </TableRow>

                              {item.modifiers &&
                                item.modifiers.map((mod) => (
                                  <TableRow key={mod.ItemModifierID}>
                                    <TableCell
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        fontSize: "medium",
                                        textAlign: "left",
                                        padding: ".5rem",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() => handleOpenEdit(item)}
                                        style={{
                                          paddingTop: "10px",
                                          zIndex: 1,
                                        }}
                                      >
                                        {/* <EditIcon /> */}
                                      </IconButton>
                                      <span style={{ paddingLeft: "35px" }}>
                                        {mod.ItemDescription}
                                      </span>
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{
                                        fontSize: "medium",
                                        padding: ".5rem",
                                      }}
                                    ></TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "medium",
                                        padding: ".5rem",
                                      }}
                                    >
                                      {/* Add any specific quantity handling for modifiers if needed */}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      style={{
                                        fontSize: "medium",
                                        padding: ".5rem",
                                      }}
                                    >
                                      {parseFloat(
                                        mod.DefaultPrice / 100
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 2,
                                      })}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </React.Fragment>
                          ))}
                        </>
                      ))}

                      {/* SUBTOTAL */}
                      {/* <TableRow
                        style={{
                          position: "sticky",
                          bottom: "-1px",
                          zIndex: 10,
                        }}
                      >
                        <TableCell
                          colSpan={2}
                          style={{
                            fontSize: "large",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          Subtotal
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          style={{
                            fontSize: "large",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        />
                        <TableCell
                          align="right"
                          style={{
                            fontSize: "large",
                            paddingRight: "8px",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          {formatCurrency(getSubtotalPrice())}
                        </TableCell>
                      </TableRow> */}

                      {/* TAX */}
                      {/* <TableRow
                        style={{
                          position: "sticky",
                          bottom: "-1px",
                          zIndex: 10,
                        }}
                      >
                        <TableCell
                          colSpan={2}
                          style={{
                            fontSize: "large",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          Tax
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          style={{
                            fontSize: "large",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        />
                        <TableCell
                          align="right"
                          style={{
                            fontSize: "large",
                            paddingRight: "8px",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          {formatCurrency(getTotalTaxAmount())}
                        </TableCell>
                      </TableRow> */}

                      {/* Conditionally render the service charge row if tableNumber includes "Table" */}
                      {!tableNumber.includes("Table") && (
                        <TableRow
                          style={{
                            position: "sticky",
                            bottom: "-1px",
                            zIndex: 10,
                          }}
                        >
                          <TableCell
                            colSpan={2}
                            style={{
                              fontSize: "large",
                              fontWeight: 550,
                              backgroundColor: greyColor,
                            }}
                          >
                            Service Charge
                          </TableCell>
                          <TableCell
                            rowSpan={1}
                            style={{
                              fontSize: "large",
                              fontWeight: 550,
                              backgroundColor: greyColor,
                            }}
                          />
                          <TableCell
                            align="right"
                            style={{
                              fontSize: "large",
                              paddingRight: "8px",
                              fontWeight: 550,
                              backgroundColor: greyColor,
                            }}
                          >
                            {formatCurrency(serviceCharge)}
                          </TableCell>
                        </TableRow>
                      )}

                      {/* DISCOUNTS */}
                      {/* <TableRow
                        style={{
                          position: "sticky",
                          bottom: "-1px",
                          zIndex: 10,
                        }}
                      >
                        <TableCell
                          colSpan={2}
                          style={{
                            fontSize: "large",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          Discounts
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          style={{
                            fontSize: "large",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        />
                        <TableCell
                          align="right"
                          style={{
                            fontSize: "large",
                            paddingRight: "8px",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          {formatCurrency(getTotalDiscount())}
                        </TableCell>
                      </TableRow> */}

                      {/* TOTAL AMOUNT */}
                      <TableRow
                        style={{
                          position: "sticky",
                          bottom: "-1px",
                          zIndex: 10,
                        }}
                      >
                        <TableCell
                          colSpan={2}
                          style={{
                            fontSize: "larger",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          Total
                        </TableCell>
                        <TableCell
                          rowSpan={1}
                          style={{
                            fontSize: "larger",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        />
                        <TableCell
                          align="right"
                          style={{
                            fontSize: "larger",
                            paddingRight: "8px",
                            fontWeight: 550,
                            backgroundColor: greyColor,
                          }}
                        >
                          {formatCurrency(getTotalPrice())}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </div>
                </Table>
              </TableContainer>
              {/* Message to Kitchen */}
              <div>
                <Typography
                  gutterBottom
                  style={{
                    fontWeight: 550,
                    fontSize: "medium",
                    margin: "1.5rem 0 .5rem 0",
                  }}
                >
                  Message to Kitchen
                </Typography>
                <Grid item xs={12}>
                  <Form provider={methods} onSubmit={handleSubmit}>
                    <Input
                      onChange={setKitchenMsg}
                      variant="outlined"
                      name="message"
                      placeholder="Enter special instructions"
                      autoComplete="off"
                      style={{
                        borderColor: "#999",
                        border: "1px solid",
                        borderRadius: "4px",
                        paddingTop: "3px",
                        backgroundColor: greyColor,
                      }}
                      fullWidth
                    />
                  </Form>
                </Grid>
              </div>
              {/* End Message to Kitchen */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <Button
                  className="si180btn"
                  sx={buttonSx}
                  style={{
                    backgroundColor: secColor,
                    color: textColor,
                    maxWidth: "200px",
                    height: "72px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    sessionStorage.removeItem("cartItems");
                    handleCloseModal();
                  }}
                >
                  Clear Order
                </Button>
                <Button
                  className="si180btn"
                  sx={buttonSx}
                  style={{
                    backgroundColor: "#265E08",
                    color: textColor,
                    maxWidth: "200px",
                    height: "72px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={submitOrder}
                >
                  Submit Order
                </Button>
              </div>
            </Box>
          </Grid>
        </Modal>
        {/* End of Order Information Modal */}
        <div style={{ textAlign: "center", color: primColor }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "left",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "75px",
                height: "75px",
                marginRight: ".5rem",
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "medium" }}>
                    Check Dietary and Allergen Info
                  </span>
                }
              >
                <img
                  src={
                    currentUser.residentImg
                      ? `${itemImgSrc}${currentUser.residentImg}`
                      : `${itemImgSrc}respic.png`
                  }
                  alt={`${currentUser.firstName} ${currentUser.lastName}`}
                  style={{
                    width: "75px",
                    height: "75px",
                    borderRadius: "50%",
                    marginRight: ".5rem",
                    border: `3px solid ${btnColor}`,
                    cursor: "pointer",
                  }}
                  onClick={handleClickOpenDiet}
                />
              </Tooltip>
              <Tooltip
                title={
                  <span style={{ fontSize: "medium" }}>
                    Check Dietary and Allergen Info
                  </span>
                }
              >
                <IconButton
                  onClick={handleClickOpenDiet}
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "-5px",
                    backgroundColor: "white",
                    border: `2px solid ${btnColor}`,
                    borderRadius: "50%",
                    padding: "3px",
                  }}
                >
                  <NoFoodIcon style={{ color: btnColor }} />
                </IconButton>
              </Tooltip>
            </div>
            <DietaryAllergyDialog open={openDiet} onClose={handleCloseDiet} />
            <div>
              <Typography style={{ fontSize: "1.125rem", lineHeight: "1.25" }}>
                Order {selectedOrderText} for
              </Typography>
              <Typography
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "1.25",
                  fontWeight: "500",
                }}
              >
                {currentUser.firstName} {currentUser.lastName}
              </Typography>
              <Typography
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "1.25",
                  fontWeight: "500",
                }}
              >
                Room #: {currentUser.roomNumber}
              </Typography>
            </div>
          </div>
          {/* general search */}
          {/* <Grid item>
            <Autocomplete />
          </Grid> */}
          {/* end general search */}
        </div>
        <Paper
          style={{ padding: 10, backgroundColor: btnColor, marginTop: "1rem" }}
        >
          <Grid container className="resMenu" alignItems="center">
            <Grid item>
              <Typography variant="h5" style={{ color: textColor }}>
                {sessionStorage.getItem("selectedMenu")}
              </Typography>
              <Typography variant="subtitle1" style={{ color: textColor }}>
                Check Number: {checkNumber}
              </Typography>
            </Grid>
            <Grid item>
              <input
                className="si180btn"
                type="text"
                placeholder="Search menu items..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Button
                className="si180btnClear"
                color="secondary"
                sx={buttonSx}
                style={{ marginLeft: 10 }}
                onClick={() => setSearchQuery("")}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Box className="itemcontainer" style={{ marginTop: 10 }}>
          <div
            className="div1"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <Grid container spacing={2}>
              {filteredItems.map((item, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                  <Card
                    style={{
                      cursor: "pointer",
                      height: "100%", // Make the card take full height
                      display: "flex", // Use flexbox for proper alignment
                      flexDirection: "column",
                      backgroundColor: greyColor,
                    }}
                    onClick={() => handleClickOpen(item)}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "100%", // Maintain 1:1 aspect ratio
                        flex: "0 0 auto", // Prevent shrinking
                      }}
                    >
                      <CardMedia
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          height: "100%",
                          width: "100%",
                        }}
                        image={
                          item.FileName
                            ? `${itemImgSrc}${item.FileName}`
                            : `${siteMedSrc}empty-img.png`
                        }
                        title={item.ItemName}
                      />
                    </div>
                    <CardContent
                      style={{
                        flex: "1 1 auto", // Allow content to grow
                        padding: ".5rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between", // Ensure space between content items
                      }}
                    >
                      <div>
                        <Typography
                          gutterBottom
                          style={{
                            display: "-webkit-box",
                            fontSize: "large",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            lineHeight: 1.2,
                          }}
                        >
                          {item.ItemName}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            lineHeight: 1.2,
                          }}
                        >
                          {item.ItemDescription}
                        </Typography>
                      </div>

                      {parseFloat(item.DefaultPrice) !== 0 && (
                        <div
                          style={{
                            position: "relative",
                            display: "grid",
                            // placeItems: "end",
                            zIndex: 1,
                          }}
                        >
                          <div className="flexRowCompact">
                            <div className="primItemDisplay">
                              <Typography
                                component="div"
                                variant="h5"
                                style={{
                                  fontWeight: 500,
                                  lineHeight: 1.2,
                                  fontSize: "1.35rem",
                                  marginTop: "15px",
                                  color: "rgb(229, 57, 53)",
                                }}
                              >
                                {formatCurrency(
                                  item.ActualPrice ?? item.DefaultPrice / 100
                                )}
                              </Typography>
                            </div>

                            {parseFloat(item.DiscountTotal) > 0 && (
                              <div className="secPriceDisplay">
                                {/* <div className="discountTag">
                                <span aria-label={`-${50}%`}>-{50}%</span>
                              </div> */}

                                <div className="origPriceTagFC">
                                  <div className="flexTruncateBaseline">
                                    <span className="truncate">
                                      {formatCurrency(item.DefaultPrice)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
          <div
            className="div2"
            style={{
              paddingLeft: "15px", // Adjust for Bootstrap's gutter width
              justifyContent: "flex-end",
            }}
          >
            <DietaryAllergyPane />
          </div>
        </Box>

        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <IconButton
            onClick={handleClose}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              backgroundColor: "#d33",
              borderRadius: "50%",
              padding: "12px",
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <CardContent>
            <Typography gutterBottom style={{ fontSize: "x-large" }}>
              {selectedItem?.ItemName}
            </Typography>

            <Grid
              container
              spacing={1}
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={4} md={3} lg={3}>
                <CardMedia
                  style={{
                    width: "100%",
                    paddingTop: "100%", // Maintains a 1:1 aspect ratio
                    boxShadow: `inset #fff 0 0 0 2px, 
                                inset #fff 0 0 0 4px, 
                                inset #ccc 0 0 0 8px, 
                                inset #999 0 0 0 10px
                              `,
                    textShadow: "3px 3px 1px #999",
                  }}
                  image={
                    selectedItem?.FileName
                      ? `${itemImgSrc}${selectedItem?.FileName}`
                      : `${siteMedSrc}empty-img.png`
                  }
                  title={selectedItem?.ItemName}
                />
              </Grid>
              <Grid className="DialogDesc" item xs={12} sm={8} md={9} lg={9}>
                <Typography
                  style={{
                    textAlign: "start",
                    fontSize: "normal",
                    lineHeight: "1.25",
                  }}
                >
                  {selectedItem?.ItemDescription}
                </Typography>
              </Grid>

              {parseFloat(selectedItem?.DefaultPrice) !== 0 && (
                <div
                  style={{
                    position: "relative",
                    display: "grid",
                    zIndex: 1,
                  }}
                >
                  <div className="flexRowCompact">
                    <div className="primItemDisplay">
                      <Typography
                        component="div"
                        variant="h5"
                        style={{
                          fontWeight: 500,
                          lineHeight: 1.2,
                          fontSize: "1.35rem",
                          marginTop: "10px",
                          color: "rgb(229, 57, 53)",
                        }}
                      >
                        {formatCurrency(
                          selectedItem?.ActualPrice ??
                            selectedItem?.DefaultPrice / 100
                        )}
                      </Typography>
                    </div>

                    {parseFloat(selectedItem?.DiscountTotal) > 0 && (
                      <div className="secPriceDisplay">
                        {/* <div className="discountTag">
                                <span aria-label={`-${50}%`}>-{50}%</span>
                              </div> */}

                        <div className="origPriceTagFC">
                          <div className="flexTruncateBaseline">
                            <span className="truncate">
                              {formatCurrency(selectedItem?.DefaultPrice)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Grid>
            <MenuItemsModifiers
              item={selectedItem}
              modifiers={modifiers}
              setModifiers={setModifiers}
            />
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                minWidth: "250px",
                height: "100%",
              }}
            >
              <Grid
                className="QtyAddCheck"
                item
                xs={12}
                sm={7}
                md={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "large",
                    textAlign: "left",
                    paddingRight: ".5rem",
                  }}
                >
                  Quantity:
                </Typography>
                <IconButton
                  style={{
                    color: textColor,
                    backgroundColor: btnColor,
                    padding: "1rem",
                  }}
                  onClick={() =>
                    setItemCount((count) => Math.max(count - 1, 1))
                  }
                >
                  <RemoveIcon />
                </IconButton>
                <Box
                  style={{
                    padding: "0 .5rem",
                    fontSize: "large",
                    fontWeight: "550",
                  }}
                >
                  {itemCount}
                </Box>
                <IconButton
                  style={{
                    color: textColor,
                    backgroundColor: btnColor,
                    padding: "1rem",
                  }}
                  onClick={() => setItemCount((count) => count + 1)}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              <Grid
                className="QtyAddCheck"
                item
                xs={12}
                sm={5}
                md={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={handleAddToCheck}
                  className="si180btn"
                  sx={buttonSx}
                  style={{
                    backgroundColor: "#265E08",
                    color: textColor,
                    maxWidth: "200px",
                    height: "60px",
                  }}
                >
                  Add to Check
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Dialog>

        {filteredItems.length > itemsPerPage && (
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            style={{
              marginTop: 20,
              justifyContent: "center",
              display: "flex",
              listStyleType: "none",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ResidentMenuItems;
