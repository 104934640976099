import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import parseHtml from "html-react-parser";
import { numberWithCommas } from "../../utils/numbersWithComma";
import { fetchItemMods } from "services/api/si180Api";

const MenuItemsModifiers = ({ item, modifiers, setModifiers }) => {
  // Default to dummyProduct if not provided
  const [required, setRequired] = useState([]);
  const [requiredCheckBox, setRequiredCheckBox] = useState([]);
  const [mods, setMods] = useState([]);

  useEffect(() => {
    const fetchMods = async () => {
      try {
        const data = await fetchItemMods(item.ItemID);
        setMods(data);
      } catch (error) {
        console.error("fetchItemMods failed:", error);
      }
    };
    fetchMods();
  }, [item.ItemID]);

  useEffect(() => {
    mods.map((mod) => {
      if (mod.ModifierMinSelect > 0) {
        setRequired((required) => [...required, mod.ItemID]);
      }
      if (mod.ModifierMinSelect > 1) {
        setRequiredCheckBox((requiredCheckBox) => [...requiredCheckBox, mod]);
      }
    });
  }, [mods]);

  if (!mods) {
    return <p>Product information is not available.</p>;
  }

  return (
    <div style={{ margin: "1rem 0rem" }}>
      {mods.map((mod, i) => (
        <div key={i}>
          {mod.ModifierMaxSelect === 1 ? (
            <FormControl fullWidth>
              <InputLabel>
                {mod.ModifierMinSelect > 0
                  ? parseHtml(mod.ModifierDescription + " <span>*</span>")
                  : mod.ModifierDescription}
              </InputLabel>
              <Select
                id={mod.ItemID.toString()}
                autoWidth={true}
                onChange={(e) => {
                  const selectedMod = e.target.value;
                  if (mod.ModifierMinSelect > 0) {
                    if (selectedMod !== "") {
                      setRequired(
                        required.filter((id) => id !== selectedMod.ParentID)
                      );
                    } else {
                      setRequired([...required, mod.ItemID]);
                    }
                  }
                  setModifiers((currentMods) =>
                    currentMods
                      .filter((m) => m.ParentID !== selectedMod.ParentID)
                      .concat(selectedMod)
                  );
                }}
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                {mod.sub_mods &&
                  mod.sub_mods.map((sub_mod) => (
                    <MenuItem key={sub_mod.ItemID} value={sub_mod}>
                      {sub_mod.DefaultPrice > 0
                        ? `${
                            sub_mod.ModifierDescription ||
                            sub_mod.ItemDescription
                          } + $${numberWithCommas(sub_mod.DefaultPrice / 100)}`
                        : sub_mod.ModifierDescription ||
                          sub_mod.ItemDescription}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : (
            <FormGroup style={{ marginTop: "5%" }}>
              <InputLabel>
                {mod.ModifierMinSelect > 0
                  ? parseHtml(mod.ModifierDescription + " <span>*</span>")
                  : mod.ModifierDescription}
              </InputLabel>
              {mod.sub_mods &&
                mod.sub_mods.map((sub_mod) => (
                  <FormControlLabel
                    key={sub_mod.ItemID}
                    control={
                      <Checkbox
                        checked={modifiers.some(
                          (m) => m.ItemID === sub_mod.ItemID
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setModifiers([...modifiers, sub_mod]);
                            setRequired(
                              required.filter((id) => id !== sub_mod.ParentID)
                            );
                          } else {
                            setModifiers(
                              modifiers.filter(
                                (m) => m.ItemID !== sub_mod.ItemID
                              )
                            );
                            setRequired([...required, sub_mod.ParentID]);
                          }
                        }}
                      />
                    }
                    label={
                      sub_mod.DefaultPrice > 0
                        ? `${
                            sub_mod.ModifierDescription ||
                            sub_mod.ItemDescription
                          } + $${numberWithCommas(sub_mod.DefaultPrice / 100)}`
                        : sub_mod.ModifierDescription || sub_mod.ItemDescription
                    }
                  />
                ))}
            </FormGroup>
          )}
        </div>
      ))}
    </div>
  );
};

export default MenuItemsModifiers;
