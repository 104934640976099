import React from "react";
import { Box, Typography, Link } from "@mui/material";

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;

export default function CustomerSatisfactionQRCode() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        height: "40vh",
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        // paddingLeft: "20px",
      }}
    >
      <Typography variant="h6" fontWeight="700" gutterBottom>
        Scan or Click to Improve Your Experience!
      </Typography>
      <img
        src={siteMedSrc + "qr-code" + ".webp"}
        alt="QR Code"
        style={{ width: "100%", maxWidth: "200px", marginBottom: "10px" }}
      />
      <Link
        href="https://smiley.link/VDRSRNNM?ui=qr"
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
      >
        https://smiley.link/VDRSRNNM?ui=qr
      </Link>
    </Box>
  );
}
