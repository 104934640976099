import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper, Tabs, Tab } from "@material-ui/core";
import LeftNav from "./LeftNav"; // Import LeftNav component
import { useHistory } from "react-router-dom";
import { fetchMenu } from "../../services/api/si180Api";
import DietaryAllergyDialog from "./DietaryAllergyDialog";
import { IconButton } from "@mui/material";
import NoFoodIcon from "@mui/icons-material/NoFood";
import Tooltip from "@mui/material/Tooltip";

const primColor = process.env.REACT_APP_SI180_PRIMCOLOR;
const textColor = process.env.REACT_APP_TEXT_COLOR;
const btnColor = process.env.REACT_APP_SI180_BTNCOLOR;
const greyColor = process.env.REACT_APP_SI180_BGGREY;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const itemImgSrc = process.env.REACT_APP_IMAGE_SRC;
const secColor = process.env.REACT_APP_SI180_SECCOLOR;

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function hexToRgb(hex) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
}

const ResidentMenuPage = ({ onResidentLookup }) => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [menu, setMenu] = useState([]);
  const selectedCustomer = JSON.parse(
    sessionStorage.getItem("selectedCustomer")
  );
  const [currentUser, setCurrentUser] = useState({
    firstName: selectedCustomer?.FirstName || "N/A",
    lastName: selectedCustomer?.LastName || "N/A",
    roomNumber: selectedCustomer?.Address1,
    residentImg: selectedCustomer?.SIC_Photo_Filename,
  });

  useEffect(() => {
    sessionStorage.setItem(
      "selectedOrderOption",
      sessionStorage.getItem("selectedOrderOption") || 0
    );
    setValue(parseInt(sessionStorage.getItem("selectedOrderOption")));
    const fetchData = async () => {
      try {
        const data = await fetchMenu();
        setMenu(data);
      } catch (error) {
        console.error("fetchMenu failed:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("selectedOrderOption", newValue);
  };

  const [openDiet, setOpenDiet] = useState(false);

  const handleClickOpenDiet = () => {
    setOpenDiet(true);
  };

  const handleCloseDiet = () => {
    setOpenDiet(false);
  };

  return (
    <div className="dashboard">
      <div>
        <LeftNav onResidentLookup={onResidentLookup} />
      </div>
      <div className="right-pane">
        <div style={{ textAlign: "center", color: primColor }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "left",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "75px",
                height: "75px",
                marginRight: ".5rem",
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontSize: "medium" }}>
                    Check Dietary and Allergen Info
                  </span>
                }
              >
                <img
                  src={
                    currentUser.residentImg
                      ? `${itemImgSrc}${currentUser.residentImg}`
                      : `${itemImgSrc}respic.png`
                  }
                  alt={`${currentUser.firstName} ${currentUser.lastName}`}
                  style={{
                    width: "75px",
                    height: "75px",
                    borderRadius: "50%",
                    marginRight: ".5rem",
                    border: `3px solid ${btnColor}`,
                    cursor: "pointer",
                  }}
                  onClick={handleClickOpenDiet}
                />
              </Tooltip>
              <Tooltip
                title={
                  <span style={{ fontSize: "medium" }}>
                    Check Dietary and Allergen Info
                  </span>
                }
              >
                <IconButton
                  onClick={handleClickOpenDiet}
                  style={{
                    position: "absolute",
                    bottom: "-5px",
                    right: "-5px",
                    backgroundColor: "white",
                    border: `2px solid ${btnColor}`,
                    borderRadius: "50%",
                    padding: "3px",
                  }}
                >
                  <NoFoodIcon style={{ color: btnColor }} />
                </IconButton>
              </Tooltip>
            </div>
            <DietaryAllergyDialog open={openDiet} onClose={handleCloseDiet} />
            <div>
              <Typography
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "1.25",
                  fontWeight: "500",
                }}
              >
                {currentUser.firstName} {currentUser.lastName}
              </Typography>
              <Typography
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "1.25",
                  fontWeight: "500",
                }}
              >
                Room #: {currentUser?.roomNumber}
              </Typography>
            </div>
          </div>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="Ordering Date"
            style={{
              backgroundColor: secColor,
              zIndex: "1",
              borderRadius: "30px",
              marginTop: ".5rem",
            }}
          >
            <Tab
              label="Order Now"
              {...a11yProps(0)}
              style={{
                height: "60px",
                fontSize: "large",
                color: value === 0 ? "white" : greyColor,
                backgroundColor:
                  value === 0 ? btnColor : `rgba(${hexToRgb(secColor)}, 0.5)`,
                fontWeight: value === 0 ? "bold" : "normal",
                lineHeight: "1.25",
                borderRadius: "30px",
              }}
            />
            <Tab
              label="Order Today"
              {...a11yProps(1)}
              style={{
                height: "60px",
                fontSize: "large",
                color: value === 1 ? "white" : greyColor,
                backgroundColor:
                  value === 1 ? btnColor : `rgba(${hexToRgb(secColor)}, 0.5)`,
                fontWeight: value === 1 ? "bold" : "normal",
                lineHeight: "1.25",
                borderRadius: "30px",
              }}
            />
            <Tab
              label="Order Tomorrow"
              {...a11yProps(2)}
              style={{
                height: "60px",
                fontSize: "large",
                color: value === 2 ? "white" : greyColor,
                backgroundColor:
                  value === 2 ? btnColor : `rgba(${hexToRgb(secColor)}, 0.5)`,
                fontWeight: value === 2 ? "bold" : "normal",
                lineHeight: "1.25",
                borderRadius: "30px",
              }}
            />
          </Tabs>
        </div>
        <Grid
          container
          spacing={2}
          justify="center"
          style={{ marginTop: ".5rem", textAlign: "center" }}
        >
          {menu.map((m) => (
            <Grid item key={m.SIC_ID} xs={12} sm={6} md={4} lg={4}>
              <Paper
                style={{
                  padding: 10,
                  backgroundColor: btnColor,
                  cursor: "pointer",
                }}
                onClick={() => {
                  sessionStorage.setItem("selectedMenu", m.MenuName);
                  history.push("/resident-items");
                }}
              >
                <Typography
                  variant="h5"
                  style={{ color: textColor, textAlign: "center" }}
                >
                  {m.MenuName}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{ textAlign: "center", color: textColor }}
                >
                  Check Number: 12345
                </Typography>
                <div style={{ textAlign: "center", margin: "10px 0" }}>
                  <img
                    src={`${siteMedSrc}${m.MenuName.toLowerCase()}.png`}
                    alt={m.MenuName}
                    onError={(e) => {
                      e.target.onerror = null; // Disable onError after one trigger
                      e.target.src = siteMedSrc + "empty-img.png"; // Set fallback image path
                    }}
                    style={{
                      width: "500px",
                      height: "300px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default ResidentMenuPage;
