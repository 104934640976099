import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import HealingIcon from "@mui/icons-material/Healing";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
}));

const itemImgSrc = process.env.REACT_APP_IMAGE_SRC;
const btnColor = process.env.REACT_APP_SI180_BTNCOLOR;
const primColor = process.env.REACT_APP_SI180_PRIMCOLOR;
const secColor = process.env.REACT_APP_SI180_SECCOLOR;

const DietaryAllergyDialog = ({ open, onClose }) => {
  const history = useHistory();
  const selectedCustomer = JSON.parse(
    sessionStorage.getItem("selectedCustomer")
  );
  const [currentUser, setCurrentUser] = useState({
    firstName: selectedCustomer?.FirstName || "N/A",
    lastName: selectedCustomer?.LastName || "N/A",
    roomNumber: selectedCustomer?.Address1,
    residentImg: selectedCustomer?.SIC_Photo_Filename,
    allergens: selectedCustomer?.Allergens
      ? selectedCustomer.Allergens.split(",").filter(
          (allergen) => allergen.trim() !== ""
        )
      : ["None"],
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <div
        style={{
          textAlign: "center",
          color: primColor,
          margin: "1rem",
          paddingTop: "1rem",
          minWidth: "400px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          <img
            src={
              currentUser.residentImg
                ? `${itemImgSrc}${currentUser.residentImg}`
                : `${itemImgSrc}respic.png`
            }
            alt={`${currentUser.firstName} ${currentUser.lastName}`}
            style={{
              width: "300px",
              height: "auto",
              borderRadius: "50%",
              border: `3px solid ${btnColor}`,
            }}
          />
          <Typography
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.25",
              fontWeight: "500",
              color: "white",
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Adds a semi-transparent background to the text for better visibility
              padding: "5px 10px",
              borderRadius: "10px",
            }}
          >
            {currentUser.firstName} {currentUser.lastName}
          </Typography>
        </div>
      </div>
      <IconButton
        onClick={onClose}
        style={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "white",
          backgroundColor: "#d33",
          borderRadius: "50%",
          padding: "12px",
        }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      {/* <DialogTitle
        variant="h5"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Dietary and Allergy Information
      </DialogTitle> */}
      <DialogContent>
        {/* <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            backgroundColor: secColor,
            padding: ".75rem",
          }}
        >
          Dietary Information
        </Typography>
        <List>
          <CustomListItem>
            <ListItemText primary="Vegetarian" />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="Gluten-Free" />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="Low-Carb" />
          </CustomListItem>
        </List> */}
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            backgroundColor: secColor,
            padding: ".75rem",
            lineHeight: 1.25,
          }}
        >
          Allergy Information
        </Typography>
        <List>
          {currentUser.allergens.map((allergen, index) => (
            <ListItem
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0 .5rem",
              }}
            >
              <HealingIcon />
              <ListItemText
                primary={allergen}
                style={{
                  fontWeight: "bold",
                  marginLeft: ".5rem",
                }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default DietaryAllergyDialog;
