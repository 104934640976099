// Imports organized by type (React, Libraries, Styles, Assets, Utils)
import React, { useState, useEffect } from "react";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory, useLocation } from "react-router-dom";
import { fetchMenuCategories } from "../../services/api/si180Api";
import "./SI180Dashboard.css";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// Environment Variables
const {
  REACT_APP_SI180_PRIMCOLOR: primColor,
  REACT_APP_TEXT_COLOR: textColor,
  REACT_APP_SI180_BTNCOLOR: btnColor,
  REACT_APP_SI180_SECCOLOR: secColor,
  REACT_APP_SI180_BGGREY: greyColor,
  REACT_APP_RESTAURANT_NAME: restaurantName,
  REACT_APP_MEDIA_SRC: siteMedSrc,
} = process.env;

// Custom hook for fetching categories
const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await fetchMenuCategories();
        const sortedCategories = data.sort(
          (a, b) => a.SIC_SortOrder - b.SIC_SortOrder
        );
        setCategories(sortedCategories);

        if (location.pathname === "/resident-items") {
          sessionStorage.setItem("selectedCategory", data[0].DepartmentName);
          window.dispatchEvent(new Event("storageChange"));
        }
      } catch (error) {
        console.error("fetchMenuCategory failed:", error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, isLoading, error };
};

// Customized Loading Component
const LoadingIndicator = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <div>
      <CircularProgress style={{ color: primColor }} />
      <p style={{ textAlign: "center", color: textColor, marginTop: "20px" }}>
        Loading menu...
      </p>
    </div>
  </div>
);

const LeftNav = ({ onResidentLookup, onResidentMenu }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    sessionStorage.getItem("selectedCategory")
  );
  const { categories, isLoading, error } = useCategories();
  const history = useHistory();
  const location = useLocation();

  const toggleNav = () => setIsNavVisible(!isNavVisible);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    sessionStorage.setItem("selectedCategory", category);
    window.dispatchEvent(new Event("storageChange"));
  };

  if (isLoading)
    return (
      <div
        style={{
          position: "fixed", // Keeps it in the viewport
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Light overlay background
          zIndex: 1000, // Ensures it's above all other content
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: primColor }} />
          <span
            style={{ marginTop: "20px", color: primColor, fontSize: "1.2rem" }}
          >
            One moment, please...
          </span>
        </div>
      </div>
    );

  //if (error) return <div>Error loading categories</div>;

  return (
    <div className="left-nav" style={{ backgroundColor: "transparent" }}>
      <div className="toggle-icon" onClick={toggleNav}>
        <MenuIcon style={{ color: primColor }} />
      </div>

      <div
        className={`nav-menu ${isNavVisible ? "visible" : ""}`}
        style={{ backgroundColor: primColor }}
      >
        <a
          href="https://servingintel.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="SI180 typemark"
            src={`${siteMedSrc}SI-180.svg`}
            style={{ padding: "1.25rem", marginTop: "10px", opacity: "81%" }}
          />
        </a>

        <NavigationButton label="Resident Lookup" path="/dashboard" />
        {sessionStorage.getItem("selectedCustomer") && (
          <NavigationButton label="Resident Menu" path="/resident-menu" />
        )}
        {location.pathname === "/resident-items" && (
          <CategoryMenu
            categories={categories}
            selectedCategory={selectedCategory}
            onCategorySelect={handleCategoryClick}
          />
        )}
      </div>
      <LogoutButton />
    </div>
  );
};

// Refactored reusable components for buttons and category menus
const NavigationButton = ({ label, path }) => {
  const history = useHistory();

  return (
    <Button
      className="primNavbtn"
      variant="contained"
      style={{
        backgroundColor: btnColor,
        color: textColor,
        marginTop: "0.5rem",
        marginLeft: ".75rem",
        borderRadius: "4px", // Add border radius here
      }}
      onClick={() => {
        if (path === "/resident-menu") {
          sessionStorage.removeItem("selectedCategory");
        }
        history.push(path);
      }}
    >
      {label}
    </Button>
  );
};

const CategoryMenu = ({ categories, selectedCategory, onCategorySelect }) => (
  <div
    style={{
      height: "calc(90vh - 175px)",
      overflowY: "auto",
      overflowX: "hidden",
    }}
  >
    <Grid
      container
      direction="column"
      spacing={1}
      justify="flex-end"
      alignItems="flex-end"
      style={{ marginTop: 10 }}
    >
      {categories.map((category, index) => (
        <Grid item key={index}>
          <Button
            className={`leftNavbtn ${
              selectedCategory === category.DepartmentName ? "selected" : ""
            }`}
            variant={
              selectedCategory === category.DepartmentName
                ? "contained"
                : "text"
            }
            style={{
              backgroundColor:
                selectedCategory === category.DepartmentName
                  ? primColor
                  : secColor,
              color: textColor,
              boxShadow: "none",
            }}
            onClick={() => onCategorySelect(category.DepartmentName)}
          >
            <span
              className={`category-text ${
                selectedCategory === category.DepartmentName
                  ? "slide-right"
                  : ""
              }`}
            >
              {category.WebDescription && category.WebDescription !== " "
                ? category.WebDescription
                : category.DepartmentName}
            </span>
          </Button>
        </Grid>
      ))}
    </Grid>
  </div>
);

const LogoutButton = () => {
  const history = useHistory();
  return (
    <Button
      style={{
        borderRadius: "28px",
        width: "56px",
        height: "56px",
        backgroundColor: secColor,
        color: textColor,
        position: "fixed",
        bottom: "10px",
        right: "10px",
      }}
      onClick={() => {
        sessionStorage.clear();
        history.push("/pin-signin");
      }}
    >
      <ExitToAppIcon />
    </Button>
  );
};

export default React.memo(LeftNav);
