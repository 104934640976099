export const groupByMenu = (data) => {
  return data?.reduce((acc, item) => {
    // Use the menu key to create a new group if it doesn't exist
    if (!acc[item.menu]) {
      acc[item.menu] = [];
    }
    // Push the current item into its corresponding group
    acc[item.menu].push(item);
    return acc;
  }, {});
};
