import React, { useState } from "react";
import { Box, Typography, ListItem, ListItemText, List } from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import HealingIcon from "@mui/icons-material/Healing";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
}));

const itemImgSrc = process.env.REACT_APP_IMAGE_SRC;
const btnColor = process.env.REACT_APP_SI180_BTNCOLOR;
const primColor = process.env.REACT_APP_SI180_PRIMCOLOR;
const secColor = process.env.REACT_APP_SI180_SECCOLOR;
const greyColor = process.env.REACT_APP_SI180_BGGREY;
const textColor = process.env.REACT_APP_TEXT_COLOR;

const DietaryAllergyPane = ({ open, onClose }) => {
  const history = useHistory();
  const selectedCustomer = JSON.parse(
    sessionStorage.getItem("selectedCustomer")
  );
  const [currentUser, setCurrentUser] = useState({
    firstName: selectedCustomer?.FirstName || "N/A",
    lastName: selectedCustomer?.LastName || "N/A",
    roomNumber: selectedCustomer?.Address1,
    residentImg: selectedCustomer?.SIC_Photo_Filename,
    allergens: selectedCustomer?.Allergens
      ? selectedCustomer.Allergens.split(",").filter(
          (allergen) => allergen.trim() !== ""
        )
      : ["None"],
  });

  return (
    <Box
      elevation={3}
      style={{
        backgroundColor: btnColor,
        padding: ".5rem",
      }}
    >
      <div
        style={{
          textAlign: "center",
          color: primColor,
          margin: ".5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          <img
            src={
              currentUser.residentImg
                ? `${itemImgSrc}${currentUser.residentImg}`
                : `${itemImgSrc}respic.png`
            }
            alt={`${currentUser.firstName} ${currentUser.lastName}`}
            style={{
              width: "300px",
              height: "auto",
              borderRadius: "50%",
              border: `3px solid ${btnColor}`,
            }}
          />
          <Typography
            style={{
              fontSize: "1.25rem",
              lineHeight: "1.25",
              fontWeight: "500",
              color: "white",
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: Adds a semi-transparent background to the text for better visibility
              padding: "5px",
              borderRadius: "10px",
            }}
          >
            {currentUser.firstName} {currentUser.lastName}
          </Typography>
        </div>
      </div>
      {/* <Box
        component="header"
        variant="h5"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Dietary and Allergy Information
      </Box> */}
      <Box component="section" p={3}>
        {/* <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            backgroundColor: secColor,
            padding: ".25rem",
          }}
        >
          Dietary Information
        </Typography>
        <List>
          <CustomListItem>
            <ListItemText primary="Vegetarian" />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="Gluten-Free" />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="Low-Carb" />
          </CustomListItem>
        </List> */}
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
            backgroundColor: secColor,
            padding: ".35rem",
            margin: ".25rem -1rem",
            lineHeight: 1.25,
          }}
        >
          Allergy Information
        </Typography>
        <List>
          {currentUser.allergens.map((allergen, index) => (
            <ListItem
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0 .5rem",
              }}
            >
              <HealingIcon style={{ color: textColor, marginLeft: "-1rem" }} />
              <ListItemText
                primary={allergen}
                style={{
                  fontWeight: "bold",
                  color: textColor,
                  marginLeft: ".5rem",
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default DietaryAllergyPane;
